<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :editable="editable"
            label="업체"
            name="assessVendorId"
            v-model="searchParam.assessVendorId">
          </c-vendor>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            :editable="editable"
            label="공종"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="평가대상 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :isInUserAll="true"
      :usePaging="false"
      selection="multiple"
      :editable="editable"
      rowKey="riskAssessmentTargetId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='src'">
          <q-btn 
            round unelevated 
            size="6px"
            color="amber" 
            icon="search"
            @click="srcClick(props.row, props.pageIndex)" />
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'risk-assess-scenario-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
          riskAssessmentPlanId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        // merge: [
        //   { index: 0, colName: 'mergeGroup' },
        //   { index: 1, colName: 'mergeGroup' },
        //   { index: 2, colName: 'mergeGroup' },
        //   { index: 3, colName: 'mergeGroup' },
        // ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '위험성평가명',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'assessVendorName',
            field: 'assessVendorName',
            label: '업체',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'sopName',
            field: 'sopName',
            label: '작업',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'riskHazardClassCd',
            field: 'riskHazardClassCd',
            label: '유해·위험요인<br/>(위험한 상황과 사건)',
            align: 'center',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'afterRiskName',
            field: 'afterRiskName',
            label: '위험성의<br/>수준',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            label: '평가일',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            label: '평가자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessOpinion',
            field: 'assessOpinion',
            label: '관련근거',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '600px'
      },
      searchParam: {
        plantCd: '',
        processCd: '',
        mdmSopId: '',
        sopName: '',
        riskHazardClassCd: null,
        riskAssessmentPlanId: '',
        assessVendorId: '',
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      rowIndex: 0,
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isComplete: false,
      hazardItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      } 
      
      if (this.popupParam.riskAssessmentPlanId) {
        this.searchParam.riskAssessmentPlanId = this.popupParam.riskAssessmentPlanId;
      } 
      // url setting
      this.listUrl = selectConfig.saas.scenario.other.url
      // code setting
      this.$comm.getComboItems('RISK_HAZARD_CLASS_CD').then(_result => {
        this.hazardItems = _result;
      })
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    srcClick(row) {
      this.row = row;

      this.popupOptions.title = '지도 위치'; // 지도 위치
      this.popupOptions.param = {
        riskAssessmentTargetId: row.riskAssessmentTargetId,
        maps: row.maps ? row.maps : [],
        sopMapId: row.sopMapId ? row.sopMapId : '',
        disabled: true,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/plan/riskAssessMap.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeMapPopup;
    },
    closeMapPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.type === 'APPLY') {
        this.row.maps = data.maps;
        this.row.deleteMaps = data.deleteMaps;
        
        if (this.row.editFlag !== 'C') {
          this.row.editFlag = 'U'
          this.row.chgUserId = this.$store.getters.user.userId
        }
      }
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '평가대상을 지정하세요.', // 평가대상을 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important
</style>
